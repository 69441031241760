import { useEffect, useState } from "react"
import { CoverImage } from "./cover-image"
import { Score } from "./score"
import { TextField } from "./text-field"
import { useStore } from "../../store"

export function Q1() {
  return (
    <div className="container-sm relative mt-[calc(43*100vw/320)]">
      <div className="ml-[calc(50*100vw/320)] relative z-10">
        <div className="title h-[calc(72*100vw/320)]">
          <span>
            Что тебе
            <br /> понравилось
          </span>
          <br /> у нас в компании?
        </div>
        <div className="h-[calc(56*100vw/320)] mt-[calc(10*100vw/320)] txt_mb_new flex flex-col gap-[2.5px] text-gray-200">
          <div>По 10-бальной шкале, где</div>
          <div>10 — все понравилось</div>
          <div>1 — не понравилось </div>
        </div>
      </div>
      <div className="mt-[calc(41.5*100vw/320)] mb-[calc(29*100vw/320)] flex justify-center relative z-10">
        <img alt="" src={require("../assets/q1.png")} className="w-[calc(158*100vw/320)] block" />
      </div>
      <Row returnIndex={0}>Обстановка в офисе комфортная</Row>
      <div className="mt-[calc(28*100vw/320)]">
        <CoverImage image1={require("../assets/q1-1.jpg")} image2={require("../assets/q1-2.png")} />
      </div>
      <Row returnIndex={1}>Удобный график работы офиса продаж?</Row>
      <Row returnIndex={2}>Мне предложили чай/кофе/сладости?</Row>
      <Row returnIndex={3}>Менеджер продаж приветливый и дружелюбный? </Row>
      <Row returnIndex={4}>Мне предоставили всю интересующую информацию? </Row>
      <div className="mt-[calc(28*100vw/320)]">
        <CoverImage image1={require("../assets/q1-4.jpg")} image2={require("../assets/q1-3.png")} />
      </div>
      <Row returnIndex={5}>Провели экскурсию по шоу-румам?</Row>
      <Row returnIndex={6}>Мне предложили квартиры в разных проектах?</Row>
      <Row returnIndex={7} input>
        Если есть что добавить, напиши сюда
      </Row>
      <div className="mt-[calc(33.8*100vw/320)] flex justify-end mr-[calc(71*100vw/320)] relative z-10">
        <img alt="" className="h-[calc(243.5*100vw/320)]" src={require("../assets/q1-5.png")} />
      </div>
    </div>
  )
}

const Row = ({ children, input = false, returnIndex }) => {
  const { addResultItem } = useStore()
  const [rating, setRating] = useState()
  const [text, setText] = useState()

  useEffect(() => {
    const res = { question: children }
    if (rating) res.grade = rating
    if (text) res.free = text
    addResultItem(res)
  }, [rating, text, children, addResultItem])

  return (
    <div
      className="flex flex-col gap-[calc(10*100vw/320)] py-[calc(15*100vw/320)] px-[20px] mx-[-20px] relative z-10"
      data-return={returnIndex}
    >
      <div className="txt text-gray-200">{children}</div>
      {input ? (
        <div className="">
          <TextField onChange={setText} />
        </div>
      ) : (
        <div>
          <Score value={rating} count={10} onChange={setRating} />
        </div>
      )}
    </div>
  )
}
