import { HelmetProvider } from "react-helmet-async"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { RouterProvider } from "react-router-dom"
import { createSurveyRoutes } from "routes"
import { queryClient } from "shared/react-query"

export function SurveyApp() {
  const routes = createSurveyRoutes()
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <RouterProvider router={routes} />
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
