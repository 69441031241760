import { useEffect, useState } from "react"
import { Checkbox } from "./checkbox"
import { CoverImage } from "./cover-image"
import { TextField } from "./text-field"
import { cx } from "shared/helpers"
import { useStore } from "../../store"

export function Q6() {
  const radioList1 = [
    {
      name: "жен"
    },
    {
      name: "муж"
    }
  ]
  const radioList2 = [
    {
      name: "младше 25"
    },
    {
      name: "26-35"
    },
    {
      name: "36-45"
    },
    {
      name: "46-55"
    },
    {
      name: "старше 56"
    }
  ]

  const radioList3 = [
    {
      name: "женат/замужем"
    },
    {
      name: "холост"
    },
    {
      name: "разведен/разведена"
    }
  ]

  const radioList4 = [
    {
      name: "НАСЛЕДИЕ"
    },
    {
      name: "Сердце Каспия"
    },
    {
      name: "Зеленые кварталы"
    },
    {
      name: "РАЗУМ в Академическом"
    },
    {
      name: "РАЗУМ на Боевой"
    }
  ]

  return (
    <div className="container-sm relative mt-[calc(45*100vw/320)]">
      <div className="title relative z-10 h-[calc(24*100vw/320)]">
        <span>расскажи</span> о себе
      </div>
      <div className="mt-[calc(20*100vw/320)]">
        <div className="relative z-10">
          <Question radioList={radioList1} inline={true} name="sex" returnIndex={12}>
            Мой пол
          </Question>
        </div>
        <div className="mt-[calc(20*100vw/320)]">
          <Question radioList={radioList2} name="age" returnIndex={13}>
            Возраст
          </Question>
        </div>
        <div className="mt-[calc(28*100vw/320)]">
          <CoverImage
            image1={require("../assets/q6-2.jpg")}
            image2={require("../assets/q6-1.png")}
          />
        </div>
        <div className="relative z-10 py-[calc(15*100vw/320)]">
          <Question radioList={radioList3} name="status" returnIndex={14}>
            Семейное положение
          </Question>
        </div>
        <div className="relative z-10 pt-[calc(15*100vw/320)]">
          <Question returnIndex={15}>Место проживания (город/улица)</Question>
        </div>
        <div className="pt-[calc(30*100vw/320)] pb-[calc(15*100vw/320)] relative z-10">
          <Question radioList={radioList4} name="city" returnIndex={16} margin>
            В каком проекте ты купил квартиру?
          </Question>
        </div>
      </div>
    </div>
  )
}

const Question = ({ children, radioList, inline, name = "", returnIndex, margin = false }) => {
  const [radioSelected, setRadioSelected] = useState([])
  const [text, setText] = useState()
  const { addResultItem } = useStore()

  const handleRadioChange = event => {
    setRadioSelected([event.target.value])
  }

  useEffect(() => {
    const res = { question: children }
    if (text) res.free = text
    if (radioSelected.length) res.answers = radioSelected
    addResultItem(res)
  }, [addResultItem, radioSelected, text, children])

  return (
    <div data-return={returnIndex}>
      <div className="txt text-gray-200">{children}</div>
      {radioList ? (
        <div
          className={cx(
            "flex gap-[15px]",
            margin ? "mt-[calc(20*100vw/320)] gap-[calc(15*100vw/320)]" : "mt-[calc(10*100vw/320)]",
            {
              "flex-col": !inline,
              "gap-[calc(10*100vw/320)]": !inline && !margin
            }
          )}
        >
          {radioList.map((item, i) => (
            <Checkbox
              key={i}
              type="radio"
              value={item.name}
              onChange={handleRadioChange}
              name={`radio-${name}`}
            >
              {item.name}
            </Checkbox>
          ))}
        </div>
      ) : (
        <div className="mt-[calc(8*100vw/320)]">
          <TextField onChange={setText} />
        </div>
      )}
    </div>
  )
}
