import { Landing } from "features/landing"
import { Layout } from "features/misc"
import { NotFound } from "shared/components/not-found"

export const surveyRoutes = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Landing />
      }
    ]
  }
]
