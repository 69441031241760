import { ReactComponent as H1Icon } from "../assets/h1.svg"

export function Hero() {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <div className="flex gap-[calc(90*100vw/1360)] items-center">
        <div className="h2 text-center">
          Привет! Пройди опрос
          <br />
          через мобильный телефон
        </div>
        <div>
          <H1Icon className="w-[calc(237*100vw/1360)] h-[calc(409*100vw/1360)] block" />
        </div>
      </div>
    </div>
  )
}
