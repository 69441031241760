import { useEffect, useState } from "react"
import { CoverImage } from "./cover-image"
import { TextField } from "./text-field"
import { useStore } from "../../store"

export function Q4() {
  const [text, setText] = useState()
  const { addResultItem } = useStore()

  useEffect(() => {
    const res = { question: "Почему ты выбрал именно наш жилой комплекс?" }
    if (text) {
      res.free = text
    }
    addResultItem(res)
  }, [text, addResultItem])
  return (
    <div className="container-sm relative mt-[calc(54*100vw/320)]" data-return={10}>
      <div className="title relative z-10 h-[calc(72*100vw/320)]">
        почему ты выбрал
        <br /> именно{" "}
        <span>
          наш жилой
          <br /> комплекс?
        </span>
      </div>
      <div className="mt-[calc(20*100vw/320)] relative z-10">
        <div className="txt text-gray-200">Твой ответ:</div>
      </div>
      <div className="mt-[calc(8*100vw/320)] relative z-10">
        <TextField onChange={setText} />
      </div>
      <div className="mt-[calc(43*100vw/320)] mb-[calc(39.5*100vw/320)]">
        <CoverImage image1={require("../assets/q4-2.jpg")} image2={require("../assets/q4-1.png")} />
      </div>
      <div className="flex justify-center relative z-10">
        <img
          alt=""
          src={require("../assets/h2.png")}
          className="h-[calc(262*100vw/320)] ml-[-16px]"
        />
      </div>
    </div>
  )
}
