import { Link } from "react-router-dom"

export function NotFound() {
  return (
    <div className="h-screen w-full flex items-center justify-center p-[20px]">
      <div className="text-center">
        <div className="h3 md:hidden text-center">Ошибка, мы не смогли найти эту страницу</div>
        <div className="text-[114px] md:text-[234px] leading-[1.3] font-medium text-gray-100 max-md:mt-[20px]">
          404
        </div>
        <div className="h3 max-md:hidden">Ошибка, мы не смогли найти эту страницу</div>
        <div className="mt-[30px] md:mt-[50px]">
          <Link to="/">
            <button className="btn">Перейти на главную</button>
          </Link>
        </div>
      </div>
    </div>
  )
}
