import { ReactComponent as H1Icon } from "../assets/h1.svg"
import { useSendResults } from "../../api"
import { useStore } from "../../store"

export function Finish({ onReturn = () => {} }) {
  const { result } = useStore()
  const { mutate: sendResults } = useSendResults()

  const getGift = () => {
    const optional = [7]
    const empty = result.findIndex((item, index) => {
      return Object.keys(item).length < 2 && !optional.includes(index)
    })
    if (empty !== -1) {
      onReturn(empty)
    } else {
      const data = JSON.stringify({
        results: result
      })
      sendResults(data)
      // переход на сторонний сервис
      window.location.href = "https://take.cards/s2Ef8"
    }
  }

  return (
    <div className="container-sm relative mt-[calc(27*100vw/320)] pb-[calc(89*100vw/320)]">
      <div className="relative w-[fit-content] ml-[calc(41*100vw/320)]">
        <H1Icon className="w-[calc(209*100vw/320)] block" />
        <div className="txt_mb_new text-gray-300 absolute top-[calc(62*100vw/320)] left-0 right-0 text-center">
          <div>
            Рад был знакомству. <br />
            Спасибо, что уделил время
          </div>
        </div>
      </div>
      <div className="flex justify-center z-10 relative mt-[calc(11.5*100vw/320)]">
        <img alt="" src={require("../assets/finish.png")} className="h-[calc(269.5*100vw/320)]" />
      </div>
      <div className="mt-[calc(56*100vw/320)] z-10 relative">
        <div className="txt text-gray-200 h-[calc(220*100vw/320)]">
          Становись частью
          <br /> сообщества «РАЗУМ»
          <br /> и скачай свою карту
          <br /> лояльности. Здесь ты
          <br /> найдешь эксклюзивные
          <br /> предложения от наших
          <br /> партнеров, закрытые
          <br /> мероприятия и интересные
          <br /> предложения от нашей
          <br /> команды.
        </div>
      </div>
      <div className="flex justify-center mt-[calc(61*100vw/320)] relative z-10">
        <div className="relative">
          <button className="btn" onClick={getGift}>
            получить подарок
          </button>
          <div className="absolute top-0 left-0 w-[calc(80*100vw/320)] translate-x-[-40%] translate-y-[-60%]">
            <img alt="" src={require("../assets/card.png")} className="block w-full" />
          </div>
        </div>
      </div>
    </div>
  )
}
