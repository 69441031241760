import { ReactComponent as OkIcon } from "../assets/ok.svg"
import { cx } from "shared/helpers"

export function Checkbox({ children, value, name = "", type = "checkbox", onChange = () => {} }) {
  return (
    <label className="flex gap-[15px] cursor-pointer min-h-[calc(22*100vw/320)]">
      <div className="custom-checkbox">
        <input name={name} value={value} type={type} onChange={onChange} />
        <div className="absolute top-0 left-0 bottom-0 right-0 border-[1.5px] border-orange-100 rounded-[50%] flex justify-center items-center w-[calc(22*100vw/320)] h-[calc(22*100vw/320)]">
          <OkIcon className="block" />
        </div>
      </div>
      <div
        className={cx({
          "txt text-gray-200": type === "checkbox",
          txt_m: type === "radio"
        })}
      >
        {children}
      </div>
    </label>
  )
}
