import { useEffect, useState } from "react"
import { Checkbox } from "./checkbox"
import { CoverImage } from "./cover-image"
import { TextField } from "./text-field"
import { useStore } from "../../store"

export function Q2() {
  const [text, setText] = useState()
  const [checked, setChecked] = useState([])
  const { addResultItem } = useStore()

  const checkList = [
    { name: "из социальных сетей" },
    { name: "увидел в интернете" },
    { name: "услышал по радио" },
    { name: "увидел наружную рекламу (баннеры по городу)" },
    { name: "увидел сам проект (проезжал мимо)" },
    { name: "на 2ГИС" },
    { name: "на Яндекс картах" },
    { name: "увидел на Дом.Клик, Авито, Циан, Яндекс.Недвижимость" },
    { name: "по рекомендации знакомых и родственников" }
  ]

  const handleCheck = event => {
    var updatedList = [...checked]
    if (event.target.checked) {
      updatedList = [...checked, event.target.value]
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1)
    }
    setChecked(updatedList)
  }

  useEffect(() => {
    const res = { question: "Как ты о нас узнал?" }
    if (checked.length) res.answers = checked
    if (text) res.free = text
    addResultItem(res)
  }, [checked, text, addResultItem])
  return (
    <div className="container-sm relative mt-[calc(58*100vw/320)]" data-return={8}>
      <div className="flex flex-col gap-[calc(12*100vw/320)] z-10 relative">
        <div className="title h-[calc(24*100vw/320)]">
          Как ты о нас <span>узнал?</span>{" "}
        </div>
        <div className="txt_mb_new text-gray-200 h-[calc(17*100vw/320)]">
          Можно отмечать несколько вариантов
        </div>
      </div>
      <div className="px-[20px] mx-[-20px] mt-[calc(25*100vw/320)] relative z-10 flex flex-col gap-[calc(16*100vw/320)]">
        {checkList.map((item, i) => (
          <Checkbox key={i} value={item.name} onChange={handleCheck}>
            {item.name}
          </Checkbox>
        ))}
        <div>
          <div className="txt text-gray-200 relative">Твой вариант</div>
          <div className="mt-[calc(8*100vw/320)]">
            <TextField onChange={setText} />
          </div>
        </div>
      </div>
      <div className="mt-[calc(28*100vw/320)] mb-[calc(33.5*100vw/320)]">
        <CoverImage image1={require("../assets/q2-1.jpg")} image2={require("../assets/q2-2.png")} />
      </div>
      <div className="relative z-10">
        <img
          alt=""
          src={require("../assets/q2.png")}
          className="h-[calc(237*100vw/320)] ml-[43px]"
        />
      </div>
    </div>
  )
}
