import { useEffect, useState } from "react"
import { CoverImage } from "./cover-image"
import { TextField } from "./text-field"
import { useStore } from "../../store"

export function Q3() {
  const [text, setText] = useState()
  const { addResultItem } = useStore()

  useEffect(() => {
    const res = { question: "Какие еще жилые комплексы ты рассматривал, прежде чем выбрать нас?" }
    if (text) {
      res.free = text
    }
    addResultItem(res)
  }, [text, addResultItem])
  return (
    <div className="container-sm relative mt-[calc(52.5*100vw/320)]" data-return={9}>
      <div className="flex flex-col gap-[calc(10*100vw/320)] relative z-10">
        <div className="title h-[calc(96*100vw/320)]">
          <span>Какие еще жилые комплексы ты рассматривал,</span> прежде чем выбрать нас?
        </div>
      </div>
      <div className="px-[20px] mx-[-20px] mt-[calc(20*100vw/320)] relative z-10">
        <div className="txt text-gray-200">Твой ответ</div>
        <div className="mt-[calc(8*100vw/320)]">
          <TextField onChange={setText} />
        </div>
      </div>
      <div className="mt-[calc(43*100vw/320)] mb-[calc(43*100vw/320)]">
        <CoverImage image1={require("../assets/q3-2.jpg")} image2={require("../assets/q3-1.png")} />
      </div>
      <div className="flex justify-center relative z-10">
        <img alt="" src={require("../assets/q3.png")} className="h-[calc(225.7*100vw/320)]" />
      </div>
    </div>
  )
}
