import { ReactComponent as H1Icon } from "../assets/h1.svg"

export function Hero() {
  return (
    <div className="relative pt-[calc(80*100vw/320)] px-[16px]">
      <div className="relative">
        <H1Icon className="w-full block" />
        <div className="txt_mb_new text-gray-300 absolute top-[calc(32*100vw/320)] left-0 right-0 text-center">
          <div>Привет!</div>
          <div>
            Я Разумныш. Поздравляю тебя
            <br /> с приобретением квартиры. Это
            <br /> знаковое событие для тебя, и для
            <br /> нас тоже. Помоги нам стать лучше,
            <br /> ответив на несколько вопросов.
            <br /> После прохождения ты получишь
            <br /> бонус. Готов? Тогда листай дальше
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-[calc(-48*100vw/320)] relative z-10">
        <img alt="" className="block w-[calc(154*100vw/320)]" src={require("../assets/h2.png")} />
      </div>
    </div>
  )
}
