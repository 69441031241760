import { http } from "shared/http"
import { useMutation } from "react-query"

export { useSendResults }

function sendResults(data) {
  return http.post("/answers", data)
}

function useSendResults({ config } = {}) {
  return useMutation({
    ...config,
    mutationFn: sendResults
  })
}
