import { Link } from "react-router-dom"
import { ReactComponent as Logo } from "../assets/logo.svg"
import { ReactComponent as LogoMd } from "../assets/logo-md.svg"
import { memo } from "react"

export const Header = memo(function Header() {
  return (
    <>
      <div className="fixed max-md:h-[calc(60*100vw/320)] top-[0] left-0 right-0 z-[100] bg-gray-100 text-white md:hidden flex items-center">
        <div>
          <Link to="/">
            <Logo className="h-[calc(45*100vw/320)] w-[calc(154*100vw/320)]" />
          </Link>
        </div>
      </div>
      <div className="max-md:hidden p-[20px] fixed top-0 left-0 right-0">
        <LogoMd className="w-[calc(382*100vw/1360)] h-[calc(170*100vw/1360)] block" />
      </div>
    </>
  )
})
