import { getStat } from "./api"

export const isObject = o => typeof o === "object" && o !== null && !Array.isArray(o)

export const localeDate = (date = new Date()) => {
  const year = date.toLocaleString("default", { year: "numeric" })
  const month = date.toLocaleString("default", { month: "2-digit" })
  const day = date.toLocaleString("default", { day: "2-digit" })
  return [year, month, day].join("-")
}

export const forceDownload = async (startDate, endDate) => {
  try {
    const res = await getStat({ startDate, endDate, download: "true" })
    const link = document.createElement("a")
    link.href = res.data.url
    link.setAttribute("download", "report.pdf")
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    alert("Нет данных для загрузки")
  }
}
