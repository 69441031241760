import { cx } from "shared/helpers"

export function CoverImage({ image1, image2, className = "" }) {
  return (
    <div className={cx("relative aspect-[280/200]", className)}>
      <img alt="" src={image1} className="block w-full h-full object-cover" />
      {image2 && <img alt="" src={image2} className="block w-full h-full cover-image" />}
    </div>
  )
}
