import { Header } from "./header"
import { Outlet } from "react-router-dom"
import { ScrollRestoration } from "react-router-dom"

export function Layout() {
  return (
    <>
      <div className="bg:white min-h-screen">
        <Header />
        <main className="h-screen">
          <Outlet />
        </main>
      </div>
      <ScrollRestoration />
    </>
  )
}
