import { QueryClient } from "react-query"

const queryConfig = {
  queries: {
    retry: 0
  }
}

export const queryClient = new QueryClient({
  defaultOptions: queryConfig
})
