import "assets/styles.css"

import { ReportApp } from "report-app"
import { SurveyApp } from "survey-app"
import { createRoot } from "react-dom/client"
import { http } from "shared/http"
import { setLogger } from "react-query"

const el = document.getElementById("root")

// апа отчета отдельно, дабы мы могли использовать
// basic http auth для локейшена report
if (process.env.REACT_APP_ID === "report") {
  createRoot(el).render(<ReportApp />)
} else if (process.env.REACT_APP_ID === "survey") {
  createRoot(el).render(<SurveyApp />)
}

let log, warn, error
log = warn = error = () => {}
setLogger({ log, warn, error })

http.interceptors.response.use(response => response.data)
