import { debounce } from "lodash"

export function TextField({ onChange = () => {} }) {
  const updateText = debounce(val => {
    onChange(val)
  }, 300)

  const onTextChange = e => {
    const value = e.target.value
    updateText(value)
  }

  return <input type="text" className="input" onChange={onTextChange} />
}
