import { useEffect, useState } from "react"
import { cx } from "shared/helpers"
import { motion } from "framer-motion"
import { times } from "lodash"

export function Score({ value = 0, count = 5, onChange = () => {} }) {
  const [val, setVal] = useState(value)
  const [commonVal, setCommonVal] = useState(value)
  const onMouseEnter = index => {
    setVal(index)
  }
  const onMouseLeave = () => {
    setVal(value)
  }

  useEffect(() => {
    setCommonVal(val || value)
  }, [value, val])

  return (
    <div>
      <div className="flex gap-[calc(6*100vw/320)]">
        {times(count, index => (
          <div
            key={index}
            onClick={() => onChange(index + 1)}
            onMouseEnter={() => {
              onMouseEnter(index + 1)
            }}
            onMouseLeave={onMouseLeave}
            className="h-[calc(22*100vw/320)]"
          >
            <motion.div
              key="fullstar"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className={cx(
                "w-[calc(22*100vw/320)] h-[calc(22*100vw/320)] border-[1.5px] border-orange-100 rounded-[50%] flex justify-center items-center txt_m",
                commonVal >= index + 1 ? "text-gray-300 bg-orange-100" : "text-gray-200 bg-white"
              )}
            >
              {index + 1}
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  )
}
