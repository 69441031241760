import create from "zustand"
import { findIndex } from "lodash"

export const useStore = create(set => ({
  result: [],
  addResultItem: item =>
    set(state => {
      const arr = [...state.result]
      const index = findIndex(state.result, { question: item.question })
      if (index === -1) {
        arr.push(item)
      } else {
        arr.splice(index, 1, item)
      }
      return {
        result: arr
      }
    })
}))
