import { useEffect, useRef } from "react"
import { Finish } from "./components/finish"
import { Hero } from "./components/hero"
import { Q1 } from "./components/q1"
import { Q2 } from "./components/q2"
import { Q3 } from "./components/q3"
import { Q4 } from "./components/q4"
import { Q5 } from "./components/q5"
import { Q6 } from "./components/q6"

export function PhabletLanding() {
  const starPathRef = useRef(null)

  useEffect(() => {
    const path = starPathRef.current
    const pathLength = path.getTotalLength()

    path.style.strokeDasharray = pathLength + " " + pathLength
    path.style.strokeDashoffset = pathLength

    function onScroll(e) {
      const scrollPercentage =
        (document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight -
          window.innerHeight)

      const drawLength = (pathLength / 1.075) * scrollPercentage
      path.style.strokeDashoffset = pathLength - drawLength
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  const scrollIntoViewWithOffset = (el, offset) => {
    window.scrollTo({
      behavior: "smooth",
      top: el.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset
    })
  }

  const onReturn = index => {
    const el = document.querySelector(`[data-return="${index}"]`)
    scrollIntoViewWithOffset(el, 67)
  }

  return (
    <div className="relative">
      <Hero />
      <Q1 />
      <Q2 />
      <Q3 />
      <Q4 />
      <Q5 />
      <Q6 />
      <Finish onReturn={onReturn} />
      <div className="top-[calc(561*100vw/320)] right-0 bottom-[calc(120*100vw/320)] left-0 absolute z-[9] pointer-events-none">
        <svg
          width="292"
          height="7789"
          viewBox="0 0 292 7789"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full object-cover"
        >
          <path
            ref={starPathRef}
            className="transition-stroke duration-300"
            d="M185.5 8C138.5 16.5 46 15.5 16.4706 115C-8.03117 197.559 26.4706 288.672 99.9691 355C140.969 392 247.001 494.195 251.971 547C255.5 584.5 234 593 200 595C166.752 596.956 166.923 572.5 171.469 565C179.955 551 210.155 558.742 222.469 572.5C235 586.5 258.487 610.052 261 671C265 768 120.509 752.5 101.5 826C84.6897 891 211.327 966.674 183.5 1037C156 1106.5 16.5 1151.5 16.5 1233.5C16.5 1323 191 1348.5 239.5 1436C280.241 1509.5 157.815 1559.98 255.5 1651.5C377 1765.34 57 1826.5 57 1960.5C57 2094.5 235.5 2098.5 258.971 2198C268.859 2239.92 245.471 2299 201.469 2326.5C160.69 2351.99 111.969 2368.5 74.4691 2360C36.9691 2351.5 10.1429 2334.99 23.4706 2317C33.4721 2303.5 58.9706 2303.5 74.4691 2326.5C86.4835 2344.33 85 2398.5 114 2454.5C138.732 2502.26 297.341 2596.3 268.5 2722C242 2837.5 31 2842 31 2948.5C31 3114 253.769 3068 261.5 3178C270.189 3301.63 87.5295 3504.5 50.5 3588.5C-15.0978 3737.31 80.0293 3760 122.5 3774C158.5 3785.87 250.5 3783 263.5 3766.5C276.5 3750 265 3738.5 254.5 3738.5C235.083 3738.5 226.417 3752.11 229.5 3766.5C231.237 3774.61 229.5 3831 197 3892C177.02 3929.5 78.5 4111 61.5 4212.5C35.8799 4365.47 276 4589.5 276 4734.5C276 4879.5 116.5 5004.5 53.5 5064.5C-17.8192 5132.42 11.3244 5269.67 86 5306C141.5 5333 222.5 5346 222.5 5404.5C222.5 5452 131.5 5505.89 131.5 5596.5C131.5 5659.5 241.917 5682 259.521 5649.5C266.021 5637.5 257.021 5615.5 233.021 5624.5C202.234 5636.04 249 5716.5 205 5748.5C167.394 5775.85 48 5755 45 5863.5C41.8215 5978.46 210.989 6079.36 192 6216.5C165 6411.5 40 6414 74.5 6516.5C98.9056 6589.01 300.54 6651.24 275 6779.5C252 6895 123.208 6902 54.5 6999C-5 7083 25.514 7190.29 54.5 7244C119.531 7364.5 268.5 7398.57 268.5 7515.5C268.5 7617.5 146.5 7684.5 146.5 7780.5"
            stroke="url(#paint0_radial_945_15)"
            strokeWidth="16"
            strokeLinecap="round"
          />
          <defs>
            <radialGradient
              id="paint0_radial_945_15"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(142.941 3893) rotate(90) scale(3887.5 134.29)"
            >
              <stop stopColor="#6B8C53" />
              <stop offset="0.2" stopColor="#6B8C53" />
              <stop offset="0.4" stopColor="#8AB769" />
              <stop offset="0.6" stopColor="#6B8C53" />
              <stop offset="0.8" stopColor="#8AB769" />
              <stop offset="1" stopColor="#6B8C53" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  )
}
