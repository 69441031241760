import { useEffect, useState } from "react"
import { CoverImage } from "./cover-image"
import { Score } from "./score"
import { TextField } from "./text-field"
import { useStore } from "../../store"

export function Q5() {
  const [rating, setRating] = useState()
  const [text, setText] = useState()
  const { addResultItem } = useStore()

  useEffect(() => {
    const res = { question: "Порекомендуешь нашу компанию свои друзьям, знакомым?" }
    if (rating) res.grade = rating
    if (text) res.free = text
    addResultItem(res)
  }, [rating, text, addResultItem])

  return (
    <div className="container-sm relative mt-[calc(59*100vw/320)]" data-return={11}>
      <div className="relative z-10">
        <div className="title h-[calc(72*100vw/320)]">
          <span>Порекомендуешь нашу компанию</span> своим друзьям, знакомым?
        </div>
        <div className="mt-[calc(16*100vw/320)] txt_mb_new text-gray-200 h-[calc(34*100vw/320)]">
          <div>10 — да, обязательно </div>
          <div>1 — нет </div>
        </div>
      </div>
      <div className="mt-[calc(16*100vw/320)] z-10 relative">
        <Score value={rating} count={10} onChange={setRating} />
        <div className="txt text-gray-200 mt-[calc(16*100vw/320)]">
          Если есть что написать, вот окошко
        </div>
      </div>
      <div className="mt-[calc(8*100vw/320)] mb-[calc(15*100vw/320)] z-10 relative">
        <TextField onChange={setText} />
      </div>
      <div className="mt-[calc(43*100vw/320)] mb-[calc(47*100vw/320)]">
        <CoverImage image1={require("../assets/q5-2.jpg")} image2={require("../assets/q5-1.png")} />
      </div>
      <div className="relative z-10">
        <img
          alt=""
          src={require("../assets/q5.png")}
          className="h-[calc(235.5*100vw/320)] ml-[calc(63*100vw/320)]"
        />
      </div>
    </div>
  )
}
