import { createBrowserRouter } from "react-router-dom"
import { reportRoutes } from "./report"
import { surveyRoutes } from "./survey"

export function createSurveyRoutes() {
  return createBrowserRouter(surveyRoutes)
}

export function createReportRoutes() {
  return createBrowserRouter(reportRoutes, {
    basename: "/report"
  })
}
